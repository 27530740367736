/* Stile generale del footer */
.footer {
    background-color: #1f63ea;
    color: white;
    padding: 40px 20px;
    text-align: center;
    margin-top: 120px;
}

.footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    gap: 20px;
}

.footer-section {
    flex: 1;
    min-width: 250px;
}

.footer-section h2, .footer-section h3 {
    font-size: 20px;
    margin-bottom: 15px;
}

.footer-section p, .footer-section ul {
    font-size: 16px;
}

.footer-section ul {
    list-style: none;
    padding: 0;
}

.footer-section ul li {
    margin: 10px 0;
}

.footer-section ul li a {
    color: white;
    text-decoration: none;
}

.footer-section ul li a:hover {
    text-decoration: underline;
}

/* Social media icons */
.social-icons {
    display: flex;
    gap: 10px;
    justify-content: center;
}

.social-icons a img {
    width: 30px;
    height: 30px;
}

/* Footer bottom section */
.footer-bottom {
    margin-top: 40px;
    padding-top: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.footer-bottom p {
    font-size: 14px;
}

.footer-bottom ul {
    list-style: none;
    padding: 0;
    margin: 10px 0 0;
    display: flex;
    gap: 15px;
    justify-content: center;
}

.footer-bottom ul li a {
    color: white;
    text-decoration: none;
}

.footer-bottom ul li a:hover {
    text-decoration: underline;
}

/* Responsività */
@media (max-width: 768px) {
    .footer-container {
        flex-direction: column;
        align-items: center;
    }

    .footer-section {
        text-align: center;
    }
}
