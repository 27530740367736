/* Modal overlay */
.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px); /* Aggiunge un effetto sfocato allo sfondo */
}

/* Modal content box */
.modal-content-home {
    background-color: #fff;
    border-radius: 10px;
    padding: 30px;
    max-width: 600px;
    width: 90%;
    position: relative;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    animation: fadeIn 0.3s ease-in-out;
    display: flex;
    overflow-y: scroll;
}

/* Modal close button */
.close {
    position: absolute;
    top: 15px;
    right: 20px;
    font-size: 30px;
    font-weight: bold;
    color: #333;
    cursor: pointer;
}

.close:hover {
    color: #1f63ea;
}

/* Animation for modal */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Card container */
.card-prenota {
    background-color: #f9f9f9;
    border-radius: 10px;
    padding: 20px;
    margin: 20px 0;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Card hover effect */
.card-prenota:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
}

/* Image in card */
.img-prenota {
    border-radius: 8px;
    width: 100%;
    max-width: 150px;
    margin-bottom: 15px;
}

/* Title in card */
.h3-prenota {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #1f63ea;
}

/* Description in card */
.p-prenota {
    font-size: 16px;
    color: #555;
    margin-bottom: 15px;
}

/* Book button */
.btn-prenota {
    background-color: #1f63ea;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 12px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.btn-prenota:hover {
    background-color: #154bb1;
    transform: translateY(-3px);
}

.btn-prenota:active {
    background-color: #0d3b8a;
    transform: translateY(0);
}

/* Responsive design */
@media (max-width: 768px) {
    .modal-content {
        padding: 20px;
        width: 95%;
    }

    .img-prenota {
        max-width: 120px;
    }

    .h3-prenota {
        font-size: 20px;
    }

    .btn-prenota {
        padding: 10px 18px;
        font-size: 14px;
    }
}
