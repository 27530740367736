.dashboard-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .dashboard-container h1 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #1f63ea;
  }
  
  .modules-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
  }
  
  .module-card {
    background: #ffffff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.2s, box-shadow 0.2s;
    display: flex;
    flex-direction: column;
  }
  .module-icon {
    flex-basis: 40%;
  }
  .moudle-h2 {
    flex-basis: 10%;
    align-items: baseline;
  }

  .module-p {
    flex-basis: 30%;
  }

.module-btn {
        flex-basis: 20%;
    }
  
  .module-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  }
  
  .module-icon {
    font-size: 3rem;
    color: #1f63ea;
    margin-bottom: 10px;
  }
  
  .module-card h2 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .module-card p {
    font-size: 1rem;
    color: #666;
    margin-bottom: 15px;
  }
  
  .module-card button {
    background: #1f63ea;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.2s;
  }
  
  .module-card button:hover {
    background: #1456c1;
  }
  