/* Modal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: #fff !important;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    max-width: 90%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
}

.modal-body {
    margin-bottom: 20px;
}

.checkbox-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.select-group {
    margin-bottom: 20px;
}

.select-group select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
}

.modal-footer {
    display: flex;
    justify-content: space-between;
}

.modal-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.modal-button.associa {
    background-color: #007bff;
    color: white;
}

.modal-button.associa:hover {
    background-color: #0056b3;
}

.modal-button.chiudi {
    background-color: #ccc;
    color: black;
}

.modal-button.chiudi:hover {
    background-color: #999;
}
