.container-collaboratori {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Stile del contenitore principale */
.table-collaboratori {
    width: 100%;
    max-width: 1200px;
    margin: 20px auto;
    border-collapse: collapse;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

/* Stile per le intestazioni della tabella */
.thead-collaboratori {
    background-color: #1f63ea;
    color: white;
    text-align: left;
    font-weight: bold;
}

.th-collaboratori {
    padding: 15px;
    border-bottom: 2px solid #ddd;
    font-size: 16px;
    text-transform: uppercase;
}

/* Stile per le righe della tabella */
.tr-collaboratori:nth-child(even) {
    background-color: #f2f2f2;
}

.tr-collaboratori:hover {
    background-color: #eaeaea;
}

/* Celle della tabella */
.td-collaboratori {
    padding: 12px 15px;
    border-bottom: 1px solid #ddd;
    text-align: left;
    font-size: 14px;
}

/* Stile per gli input di testo */
input[type="text"], input[type="number"] {
    width: 100%;
    padding: 8px;
    margin: 4px 0;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
}

/* Stile per il select */
select {
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 14px;
    background-color: white;
    cursor: pointer;
}

/* Focus per input e select */
input:focus, select:focus {
    border-color: #1f63ea;
    box-shadow: 0 0 5px rgba(31, 99, 234, 0.5);
    outline: none;
}

/* Responsività per schermi piccoli */
@media (max-width: 768px) {
    .table-collaboratori {
        width: 100%;
        margin: 10px auto;
    }

    .thead-collaboratori, .td-collaboratori {
        font-size: 12px;
        padding: 10px;
    }

    input[type="text"], input[type="number"], select {
        padding: 6px;
    }
}

/* Stile per il contenitore del pulsante e della tabella */
.container-collaboratori {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Spazio tra il pulsante e la tabella */
    margin: 20px 0;
    width: 100%;
    align-items: center;
}

/* Stile per il pulsante Aggiungi Collaboratore */
.button-collaboratori {
    background-color: #1f63ea;
    color: white;
    padding: 12px 20px;
    font-size: 16px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    font-weight: bold;
    margin-top: 20px;

}

/* Hover effetto per il pulsante */
.button-collaboratori:hover {
    background-color: #154bb1;
    transform: translateY(-3px);
    box-shadow: 0 6px 14px rgba(0, 0, 0, 0.15);
}

/* Effetto quando il pulsante viene cliccato */
.button-collaboratori:active {
    background-color: #0d3b8a;
    transform: translateY(0);
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
}

/* Effetto focus per accessibilità */
.button-collaboratori:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(31, 99, 234, 0.5);
}

/* Stile per la tabella collaboratori */
.table-collaboratori {
    width: 100%;
    border-collapse: collapse;
}

.table-collaboratori th, .table-collaboratori td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
}


.form-collaboratori {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.form-collaboratori input,
.form-collaboratori select,
.form-collaboratori iframe {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    transition: border-color 0.3s ease;
}

.form-collaboratori input:focus,
.form-collaboratori select:focus {
    border-color: #007bff;
    outline: none;
}

.form-collaboratori button {
    background-color: #007bff;
    color: white;
    padding: 12px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.form-collaboratori button:hover {
    background-color: #0056b3;
}

.form-collaboratori button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.form-collaboratori h2 {
    text-align: center;
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
}

.thead-collaboratori:hover {
    background-color: #1f63ea !important;
    color: white;
    text-align: left;
    font-weight: bold;
}

#thead-coll {
    background-color: #1f63ea;
    color: white;
    text-align: left;
    font-weight: bold;
}

.container-btn-collaboratori {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.btn-collaboratori-modifica {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 10px;
}
.btn-collaboratori-elimina {
    background-color: #dc3545;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* 
creo lo style di questa modale
            <div className='container-modal-elimina-collaboratori'>
                <div className='modal-elimina-collaboratori'>
                    <h2>Sei sicuro di voler eliminare il collaboratore?</h2>
                    <div className='container-buttons-modal-elimina-collaboratori'>
                        <button onClick={() => {
                            handleEditCollaboratore(indexToDel)
                            setShowModalElimina(false)
                        }
                        }>Si</button>
                        <button onClick={() => setShowModalElimina(false)}>No</button>
                    </div>
                </div>
            </div>
*/

.container-modal-elimina-collaboratori {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.modal-elimina-collaboratori {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.container-buttons-modal-elimina-collaboratori {
    display: flex;
    justify-content: space-between;
}

.container-buttons-modal-elimina-collaboratori button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.container-buttons-modal-elimina-collaboratori button:hover {
    background-color: #f8d7da;
}

.container-buttons-modal-elimina-collaboratori button:first-child {
    background-color: #dc3545;
    color: white;
}

.container-buttons-modal-elimina-collaboratori button:last-child {
    background-color: #007bff;
    color: white;
}

/* Stile per la tabella collaboratori */