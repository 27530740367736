.calcolatore-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    margin-top: 120px;
    margin-bottom: 120px;
}

h1 {
    color: #e6005c;
}

.form-selezione,
.form-dettagli {
    margin-bottom: 20px;
}

input[type="number"],
select {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
}

.btn-calcola  {
    background-color: #e6005c;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
}

.btn-calcola:hover {
    background-color: #d40053;
}

.risultato {
    margin-top: 20px;
    padding: 15px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    text-align: center;
}
/* Stile per la sezione di spiegazione del calcolatore bagni */
.calcolo-bagni-info {
    background-color: #f1f1f1;
    padding: 20px;
    margin: 20px auto;
    border-radius: 8px;
    max-width: 800px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.calcolo-bagni-info h2 {
    color: #1f63ea;
    font-size: 28px;
    margin-bottom: 15px;
}

.calcolo-bagni-info p {
    font-size: 18px;
    color: #333;
    margin-bottom: 10px;
}
