div[data-rbd-droppable-id="cardPool"] {
    /* Stili solo per div con l'attributo specifico */
    width: 80vw;
    flex-wrap: wrap;
}

.container-info {
    display: flex ;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    font-size: 20px;
    text-align: center;
}

/* Stile generale per il contenitore */
.App-drag {
    font-family: Arial, sans-serif;
    color: #333;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    margin-left: 0px;
}

/* Titolo principale */
.App-drag h1 {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
    color: #007bff;
}

/* Contenitori delle colonne */
.App-drag .droppable-container {
    display: flex;
    justify-content: space-between;
    gap: 15px;
}

/* Colonne */
.App-drag .column {
    border: 1px solid #ddd;
    border-radius: 8px;
    background: #f9f9f9;
    padding: 15px;
    width: 160px;
    min-height: 300px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.App-drag .column h3 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #007bff;
    text-align: center;
}

/* Card */
.App-drag .card {
    padding: 15px;
    margin-bottom: 10px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: grab;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    transition: transform 0.2s;
}

.App-drag .card:active {
    cursor: grabbing;
    transform: scale(1.02);
}

/* Informazioni nella card */


/* Card Pool */
.card-pool-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background: #f3f3f3;
    margin-top: 20px;
}

.card-pool-container h2 {
    width: 100%;
    font-size: 20px;
    margin-bottom: 15px;
    color: #007bff;
    text-align: center;
}

/* Evidenziazione Drag-and-Drop */
[data-rbd-droppable-id="cardPool"] {
    background-color: #e9f5ff;
    transition: background-color 0.3s;
}

[data-rbd-droppable-id]:hover {
    background-color: #f0f8ff;
}
.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 70vh;
}

/* Media Query per dispositivi mobili */
@media (max-width: 768px) {
    .App-drag .droppable-container {
        flex-direction: column;
        align-items: center;
        margin-left: 70px;
    }

    .App-drag .column {
        width: 90%;
        margin-bottom: 15px;
    }

    .card-pool-container {
        flex-direction: column;
        align-items: center;
    }
    .card-pool-title {
        display: none;
    }
}
