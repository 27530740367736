.main-login-gestore {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #000000;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Ombra per profondità */
}

.main-login-gestore form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 15px 0;
    width: 100%;
    max-width: 400px; /* Limita la larghezza del form per mantenerlo compatto */
}

.main-login-gestore label {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 600;
    color: #363636; /* Colore leggermente attenuato per il contrasto */
}

.main-login-gestore input {
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #dcdcdc;
    width: 100%;
    max-width: 350px; /* Limita la larghezza degli input */
    font-size: 14px;
    outline: none;
    transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Transizione per focus */
}

.main-login-gestore input:focus {
    border-color: #3498db; /* Colore di evidenza su focus */
    box-shadow: 0 0 5px rgba(52, 152, 219, 0.3); /* Effetto di focus */
}

.main-login-gestore button {
    margin: 10px 0;
    padding: 10px 20px;
    border-radius: 8px;
    background-color: #ffffff;
    color: #1f63ea;
    border: 1px solid #1f63ea; /* Bordo per migliorare la struttura */
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s ease; /* Transizioni fluide */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Leggera ombra per effetto 3D */
}

.main-login-gestore button:hover {
    background-color: #1f63ea;
    color: #ffffff; /* Inverte i colori su hover */
    transform: translateY(-2px); /* Leggero sollevamento per interazione */
}

.main-login-gestore button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(31, 99, 234, 0.3); /* Effetto di focus */
}

.registrati-login {
    cursor: pointer;
    font-size: 14px;
    color: #3498db;
    text-decoration: underline;
    transition: color 0.3s ease;
}

.registrati-login:hover {
    color: #1abc9c; /* Cambia colore al passaggio del mouse */
}

.button-generic {
    margin: 10px 0;
    padding: 10px 20px;
    border-radius: 8px;
    background-color: #f1f1f1;
    color: #2c3e50;
    border: 1px solid #dcdcdc;
    transition: background-color 0.3s ease, color 0.3s ease;
    cursor: pointer;
}

/* Media query per dispositivi mobili */
@media (max-width: 768px) {
    .main-login-gestore {
        padding: 15px;
        margin: 10px;
        border-radius: 8px;
    }

    .main-login-gestore form {
        max-width: 300px;
    }

    .main-login-gestore input {
        max-width: 100%;
    }
}
