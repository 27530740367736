.table-container {
    width: 100%;
    overflow-x: auto; /* Permette lo scroll orizzontale se necessario */
    max-height: 100vh;
    overflow-y: scroll;
  }
  
  .responsive-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 1em;
    text-align: left;
  }
  
  .responsive-table thead {
    background-color: #333;
    color: #fff;
  }
  
  .responsive-table th, .responsive-table td {
    padding: 12px 15px;
    border: 1px solid #ddd;
    font-size: 13px;
  }
  
  .responsive-table tbody tr {
    border-bottom: 1px solid #ddd;
  }
  
  .responsive-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
  }
  .internal-row {
    padding:0;
    margin:0;
  }

  .custom-select-giri-consegne {
    width: 100%;
    max-width: 300px; /* Limita la larghezza massima */
    padding: 10px 15px;
    font-size: 16px;
    color: #333;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: all 0.3s ease;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    appearance: none; /* Rimuove lo stile predefinito del browser */
    cursor: pointer;
}

.custom-select-giri-consegne:hover {
    border-color: #1f63ea; /* Cambia colore al bordo al passaggio del mouse */
    box-shadow: 0px 3px 8px rgba(31, 99, 234, 0.3); /* Effetto ombra */
}

.custom-select-giri-consegne:focus {
    border-color: #1f63ea;
    outline: none; /* Rimuove il contorno predefinito */
    box-shadow: 0px 3px 8px rgba(31, 99, 234, 0.5);
}

/* Aggiunta di un'icona a destra per la select */
.custom-select-giri-consegne::after {
    content: "▼"; /* Icona per il dropdown */
    font-size: 12px;
    color: #999;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}

  /* Stili per dispositivi mobili */
  @media screen and (max-width: 768px) {
    .blue {
      background-color: #84aaf71c !important;
      border: 1px solid #000000 !important;
      margin-top: 10px;
  
    }
    .red {
      margin-top: 10px;
      background-color: #f3b6b63f !important;
      border: 1px solid #000000 !important;
    }
    .responsive-table {
     margin-bottom: 100px;
    }
    .responsive-table thead {
      display: none;
    }
  
    .responsive-table, .responsive-table tbody, .responsive-table tr, .responsive-table td {
      display: block;
      width: 100%;
    }
  
    .responsive-table tr {
      margin-bottom: 15px;
    }
  
    .responsive-table td {
      text-align: right;
      padding-left: 50%;
      position: relative;
    }
  
    .responsive-table td::before {
      content: attr(data-label);
      position: absolute;
      left: 0;
      width: 50%;
      padding-left: 15px;
      font-weight: bold;
      text-align: left;
    }
    .totali td::before {
      content: attr(data-label); /* Usa gli attributi data-label per i titoli delle colonne */
      font-weight: bold;
      margin-right: 10px;
      color: #1f63ea;
      flex-shrink: 0;
      display: flex;
      justify-content: end;
      width: 100%;
    }
    .totali tr {
      margin-top: 15px;
      margin-bottom: 15px;
    }

  }
  


  /* Stile base per i bottoni */
.btn-table {
  padding: 10px 15px;
  margin: 5px;
  background-color: #1f63ea;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

/* Hover - cambia colore e aggiunge ombreggiatura */
.btn-table:hover {
  background-color: #1553b0;
  box-shadow: 0px 4px 8px rgba(235, 15, 15, 0.1);
}

/* Focus - effetto per accessibilità */
.btn-table:focus {
  outline: none;
  box-shadow: 0 0 0 4px rgba(31, 99, 234, 0.3);
}

/* Click attivo - effetto per il feedback al click */
.btn-table:active {
  background-color: #0d3d85;
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.2);
}

/* Stile per bottoni di "Elimina" - rosso per avvisi */
.btn-table.elimina {
  background-color: #d9534f;
}

.btn-table.elimina:hover {
  background-color: #c9302c;
}

.btn-table.elimina:active {
  background-color: #ac2925;
}


.lds-ellipsis,
.lds-ellipsis div {
  box-sizing: border-box;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33.33333px;
  width: 13.33333px;
  height: 13.33333px;
  border-radius: 50%;
  background: currentColor;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
.raggruppa-dati {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}
.raggruppa-dati-element {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-basis: 20%;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}


/* Responsività per schermi piccoli */
@media (max-width: 600px) {
  .btn-table {
      width: 100%;
      padding: 12px;
      margin: 8px 0;
  }
  .raggruppa-dati-element {
      flex-basis: 100%;
  }
}


.responsive-table th, .responsive-table td {
 /* min-width: 100px;*/
  vertical-align: top;
}

/* Larghezza fissa per la colonna delle azioni */
.responsive-table td:last-child {
  width: 150px;
  text-align: center;
}