/* Stile principale per il contenitore di aggiunta */
.aggiungi {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    padding: 20px;
    /*max-width: 800px;*/
    background-color: #ffffff;
    color: #333;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    flex-basis: 100%;
}

/* Stile per il titolo */
.aggiungi h2 {
    font-size: 24px;
    font-weight: 600;
    margin: 0;
    color: #1f63ea;
}

/* Stile per i paragrafi */
.aggiungi p {
    font-size: 16px;
    color: #555;
    margin: 0;
    line-height: 1.5;
}

/* Contenitore per l'aggiunta di servizi */
.container-aggiungi-servizio {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    background-color: #fafafa;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
}

/* Stile per gli input */
.container-aggiungi-servizio input {
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 14px;
    width: calc(100% - 24px);
    box-sizing: border-box;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

/* Effetto focus sugli input */
.container-aggiungi-servizio input:focus {
    border-color: #1f63ea;
    box-shadow: 0 0 0 2px rgba(31, 99, 234, 0.2);
    outline: none;
}

/* Stile per i pulsanti */
.container-aggiungi-servizio button {
    padding: 12px 20px;
    background-color: #1f63ea;
    color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
.aggiungi-auto-da-csv{
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    background-color: #fafafa;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
}
.aggiungi-auto-da-csv button {
    padding: 12px 20px;
    background-color: #1f63ea;
    color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* Effetto hover per i pulsanti */
.container-aggiungi-servizio button:hover {
    background-color: #0056b3;
}
.aggiungi-auto-da-csv button:hover {
    background-color: #0056b3;
}
/* Stile per il selettore */
.select-aggiungi-stato {
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #ffffff;
    color: #333;
    font-size: 14px;
    cursor: pointer;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}
.select-aggiungi-impiego {
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #ffffff;
    color: #333;
    font-size: 14px;
    cursor: pointer;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

/* Effetto focus sul selettore */
.select-aggiungi-stato:focus {
    border-color: #1f63ea;
    box-shadow: 0 0 0 2px rgba(31, 99, 234, 0.2);
    outline: none;
}

.select-aggiungi-impiego:focus {
    border-color: #1f63ea;
    box-shadow: 0 0 0 2px rgba(31, 99, 234, 0.2);
    outline: none;
}

/* Media query per la responsività */
@media (max-width: 768px) {
    .aggiungi {
        padding: 0px;
        margin: 0px;
        gap: 15px;
        width: 90vw;
    }

    .container-aggiungi-servizio {
        padding: 15px;
        gap: 10px;
    }

    .aggiungi h2 {
        font-size: 20px;
    }

    .container-aggiungi-servizio input,
    .container-aggiungi-servizio button,
    .select-aggiungi-stato {
        padding: 10px;
        font-size: 13px;
    }
    .select-aggiungi-impiego {
        padding: 10px;
        font-size: 13px;
    }
}
