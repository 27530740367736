/* Contenitore principale del form */
.profilo-form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: #1f63ea;
    color: white;
    padding: 20px;
    margin: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 100%;
    gap: 15px;
    align-self: center;
}

/* Stile per il titolo */
.profilo-form-container h2 {
    font-size: 20px;
    margin-bottom: 15px;
    text-align: left;
    font-weight: 600;
}

/* Stile del form */
.profilo-form-container form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

/* Stile degli input */
.profilo-form-container input {
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #ddd;
    width: 100%;
    font-size: 14px;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

/* Effetto hover e focus sugli input */
.profilo-form-container input:focus {
    border-color: #0056b3;
    box-shadow: 0 0 0 2px rgba(0, 86, 179, 0.2);
    outline: none;
}

/* Stile per il bottone (se aggiunto) */
.profilo-form-container button {
    margin: 10px 0;
    padding: 10px;
    border-radius: 8px;
    background-color: #4caf50;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* Effetto hover per il bottone */
.profilo-form-container button:hover {
    background-color: #45a049;
}

/* Stile per il link di onboarding */
.profilo-form-container span {
    color: #ffeb3b;
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline;
    transition: color 0.3s ease;
}

/* Effetto hover per il link */
.profilo-form-container span:hover {
    color: #ffc107;
}

.visualizzazione-profilo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: #1f63ea;
    color: white;
    padding: 20px;
    margin: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 100%;
    gap: 15px;
    align-self: center;
}

.info-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.form-profilo > input {
    color: black;
}
.form-profilo > select > option {
    color: black;
}
.form-profilo > select  {
    color: black;
}
/* Media query per dispositivi mobili */
@media (max-width: 768px) {
    .profilo-form-container {
        padding: 15px;
        margin: 10px;
        border-radius: 10px;
        max-width: 90%;
    }

    .profilo-form-container h2 {
        font-size: 18px;
    }

    .profilo-form-container input {
        padding: 8px;
        font-size: 13px;
    }
}
