/* Stile generale per il contenitore */
.container-collaboratori-login {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.container-collaboratori-login h2 {
    font-size: 24px;
    font-weight: bold;
    color: #1f63ea;
    margin-bottom: 20px;
}

/* Stile per il gruppo del form */
.form-group-collaboratori-login {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
    text-align: left;
}

.form-group-collaboratori-login label {
    font-size: 16px;
    font-weight: 500;
    color: #333;
}

/* Stile per il campo input */
.form-group-collaboratori-login input[type="password"] {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-sizing: border-box;
    box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease;
}

.form-group-collaboratori-login input[type="password"]:focus {
    border-color: #1f63ea;
    outline: none;
    box-shadow: 0 0 5px rgba(31, 99, 234, 0.3);
}

/* Stile per il pulsante */
.btn-primary {
    width: 100%;
    padding: 12px;
    font-size: 18px;
    color: white;
    background-color: #1f63ea;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn-primary:hover {
    background-color: #154cbb;
}

/* Effetto ombra e transizione */
.btn-primary:active {
    transform: translateY(2px);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

/* Responsività */
@media (max-width: 480px) {
    .container-collaboratori-login {
        padding: 15px;
        margin: 30px auto;
    }

    .container-collaboratori-login h2 {
        font-size: 22px;
    }

    .form-group-collaboratori-login input[type="password"] {
        font-size: 14px;
    }

    .btn-primary {
        font-size: 16px;
    }
}
