/* Contenitore principale */
.totali {
    /*max-width: 800px;*/
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    color: #333;
    flex-basis: 100%;
}
.cont-button-bagni-totali {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
}
/* Titolo principale */
.totali h2 {
    font-size: 24px;
    font-weight: bold;
    color: #1f63ea;
    margin-bottom: 20px;
    text-align: center;
}

/* Paragrafo di caricamento o errore */
.totali p {
    font-size: 16px;
    color: #555;
    text-align: center;
}

/* Messaggio di errore */
.totali p.error {
    color: #d9534f;
    font-weight: bold;
}

/* Contenitore per i bottoni */
/*.cont-button-bagni-totali {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}*

/* Stile dei bottoni */
.cont-button-bagni-totali button {
    padding: 10px 20px;
    background-color: #1f63ea;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* Hover sui bottoni */
.cont-button-bagni-totali button:hover {
    background-color: #0056b3;
}

/* Stato attivo dei bottoni */
.cont-button-bagni-totali button:active {
    background-color: #003f7f;
}

/* Contenitore per la mappa o la tabella */
.mostra-mappa-totali,
.mostra-tabella-totali {
    margin-top: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Responsività per schermi più piccoli */
@media (max-width: 768px) {
    .cont-button-bagni-totali {
        flex-direction: column;
        gap: 10px;
    }

    .totali h2 {
        font-size: 20px;
    }

    .cont-button-bagni-totali button {
        width: 100%;
        padding: 12px;
    }
    .pointer-img {
        cursor: pointer;
        width: 20px;
        height: 20px;
    }
    .totali {
        width: 90vw;
        padding:0px;
    }
}
