.table-container {
    max-width: 1420px;
    margin: 0 auto;
  }
  
  .input-group {
    margin-bottom: 20px;
    padding: 10px;
    width: 100%;
    max-width: 300px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .responsive-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .responsive-table th,
  .responsive-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .responsive-table th {
    background-color: #f2f2f2;
  }
  
  .responsive-table tr:hover {
    background-color: #f1f1f1;
  }
  
  .btn-table {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 8px 12px;
    margin-right: 5px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .btn-table:hover {
    background-color: #45a049;
  }
  
  .delimiter-row {
    background-color: #e0e0e0;
    height: 10px; /* Aggiungi spazio tra i gruppi */
  }
  

  .responsive-table th {
    background-color: #000000;
}


.custom-select-giri-consegne {
  width: auto;
  max-width: 300px; /* Limita la larghezza massima */
  padding: 10px 15px;
  font-size: 16px;
  color: #333;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: all 0.3s ease;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  appearance: none; /* Rimuove lo stile predefinito del browser */
  cursor: pointer;
}

.custom-select-giri-consegne:hover {
  border-color: #1f63ea; /* Cambia colore al bordo al passaggio del mouse */
  box-shadow: 0px 3px 8px rgba(31, 99, 234, 0.3); /* Effetto ombra */
}

.custom-select-giri-consegne:focus {
  border-color: #1f63ea;
  outline: none; /* Rimuove il contorno predefinito */
  box-shadow: 0px 3px 8px rgba(31, 99, 234, 0.5);
}

/* Aggiunta di un'icona a destra per la select */
.custom-select-giri-consegne::after {
  content: "▼"; /* Icona per il dropdown */
  font-size: 12px;
  color: #999;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}


.lds-ellipsis,
.lds-ellipsis div {
  box-sizing: border-box;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}


.lds-ellipsis div {
  position: absolute;
  top: 33.33333px;
  width: 13.33333px;
  height: 13.33333px;
  border-radius: 50%;
  background: currentColor;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
.etichetta-responsive {
  display: none;
}
.etichetta-responsive-int {
  height: 120px;
}
.pallinoRosso{
  display: inline-block; 
  width: 8px; 
  height: 8px; 
  background-color: red; 
  border-radius: 50%
}
.pallinoVerde{
  display: inline-block; 
  width: 8px; 
  height: 8px; 
  background-color: rgb(30, 143, 15); 
  border-radius: 50%
}
@media (max-width: 768px) {

.etichetta-responsive {
  display: block;
  font-size: 16px;
  font-weight: bold;
  color: #1f63ea;
  margin-bottom: 10px;
  margin-right: 10px;
}
  .blue {
    background-color: #8cb0f84d !important;
    border: 1px solid #000000 !important;

  }
  .red {
    margin-top: 10px;
    background-color: #f7aeae4b !important;
    border: 1px solid #000000 !important;
  }
  .delimiter{
    height: 50px; /* Aggiungi spazio tra i gruppi */
  }
    .responsive-table tr {
        margin-bottom: 0px;
    }

    .table-prenotazioni {
        font-size: 14px;
    }

    .thead-prenotazioni th,
    .tbody-prenotazioni td {
        padding: 8px;
    }

    /* Tabella a schede per dispositivi mobili */
    .table-prenotazioni {
        display: block;
    }

    .thead-client {
        display: none; /* Nasconde l'intestazione della tabella */
    }

  .tbody-prenotazioni td::before {
    content: attr(data-label); /* Usa gli attributi data-label per i titoli delle colonne */
    font-weight: bold;
    margin-right: 10px;
    color: #1f63ea;
    flex-shrink: 0;
  }
}