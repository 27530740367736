

.noleggio-main-container {
    flex-basis: 100%;
    display: flex;
}

.noleggio-container-data {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    /*margin: 10px;*/
    padding: 15px;
    border-radius: 12px;
    background-color: #ffffff; /* Colore di sfondo più luminoso */
    color: #333; /* Colore del testo più scuro per migliore leggibilità */
    height: 100%; /* L'altezza massima consentita */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05); /* Ombra delicata per profondità */
}

.actived-class {
    color: #2980b9;/* Colore del testo più scuro */
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Ombra leggera */
}

.noleggio-data-barralaterale {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
    margin: 10px;
    padding: 20px;
    border: 3px solid #2980b9; /* Colore bordo raffinato */
    border-radius: 8px;
    background-color: #f4f6f8; /* Colore di sfondo neutro e pulito */
    color: #2c3e50; /* Colore del testo migliorato */
    flex-basis: 20%;
    cursor: pointer;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Ombra leggera */
    transition: border-color 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease; /* Aggiunto effetto hover */
    gap: 5px;
    position: relative;
}



.noleggio-data-corpo-centrale {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    margin: 10px;
    padding: 20px;
    border-radius: 12px;
    background-color: #ffffff; /* Uniformità di sfondo */
    color: #333; /* Testo più leggibile */
    flex-basis: 80%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05); /* Ombra per continuità estetica */
}

.noleggio-data-barralaterale-corpo-titolo {
    display: flex;
    flex-basis: 100%;

}

.noleggio-data-barralaterale-titolo :hover {
    color: #1abc9c; /* Cambio colore testo in hover */

    /* voglio che il div si sollevi di 2px in hover */
    transform: translateY(-2px); /* Leggero sollevamento */
    /* cambio colore bordo in hover */
    border-color: #1abc9c; /* Cambio colore bordo in hover */
    /* maggiore profondità in hover */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.062); /* Maggiore profondità in hover */
    /* cambio colore di sfondo in hover deve essere sfocato e quasi trasparente */
    background-color: rgba(0, 0, 0, 0.021); /* Cambio colore di sfondo in hover */

    
}

.noleggio-data-barralaterale-titolo{
    border-width: 1px;
    padding: 3px;
    border-style: solid;
    border-color: #2980b9;
    border-radius: 8px;
    background-color: #f4f6f8;
    color: #2c3e50;
    cursor: pointer;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease;
    align-self: stretch;

}


.container-select {
    display: flex;
    flex-direction: column;
    gap: 8px; /* Spazio tra etichetta e selettore */
    margin: 15px 0;
    padding: 10px 15px;
    border: 1px solid #dfe6e9; /* Bordo leggero per definire il contorno */
    border-radius: 8px;
    background-color: #ffffff; /* Sfondo chiaro */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05); /* Ombra leggera per profondità */

    align-self: baseline;
    padding: 7px;
}

.label-prodotto {
    font-size: 14px;
    font-weight: 600;
    color: #2c3e50; /* Colore del testo scuro per leggibilità */
    margin-bottom: 4px;
}

select {
    padding: 10px 12px;
    font-size: 14px;
    border: 1px solid #bdc3c7; /* Colore del bordo del selettore */
    border-radius: 5px;
    background-color: #ecf0f1; /* Sfondo selettore */
    color: #2c3e50; /* Colore testo del selettore */
    outline: none; /* Rimozione bordo di default */
    transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Transizione per effetti hover */
    appearance: none; /* Rimuove la freccia di default su alcuni browser */
    cursor: pointer;
}

select:focus {
    border-color: #3498db; /* Cambio colore del bordo su focus */
    box-shadow: 0 0 4px rgba(52, 152, 219, 0.5); /* Aggiunge un'ombra blu su focus */
}

option {
    color: #2c3e50; /* Colore testo delle opzioni */
    padding: 10px; /* Spaziatura per opzioni */
}


.titolo-pagina-dashborad {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Spazio tra titolo e pulsante */
    padding: 15px 20px;
    background-color: #f4f6f8; /* Sfondo neutro e professionale */
    /*border-radius: 10px;*/
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05); /* Ombra leggera per un effetto di profondità */
    margin-bottom: 6px; /* Spazio sotto la sezione per separazione */
}

.titolo-pagina-dashborad h2 {
    margin: 0;
    font-size: 24px;
    font-weight: 700;
    color: #2c3e50; /* Colore scuro per il testo del titolo */
}

.go-back-btn {
    padding: 10px 16px;
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    background-color: #3498db; /* Colore blu professionale per il pulsante */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease; /* Transizioni per effetto hover */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Ombra per dare profondità al pulsante */
}

.go-back-btn:hover {
    background-color: #2980b9; /* Colore di hover per il pulsante */
    transform: translateY(-2px); /* Leggero sollevamento in hover */
}

.go-back-btn:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.3); /* Evidenziazione su focus */
}

.filtri-noleggio {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start; /* Spazio tra titolo e pulsante */
    padding: 15px 20px;
    background-color: #f4f6f8; /* Sfondo neutro e professionale */
    /*border-radius: 10px;*/
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05); /* Ombra leggera per un effetto di profondità */
    margin-bottom: 6px; /* Spazio sotto la sezione per separazione */
    gap: 20px;
}
.elementi-filtri{
    flex-basis: 20%;
}
/* noleggio da cellulare flex column*/
@media (max-width: 768px) {
    .noleggio-container-data {
        flex-direction: column;
        align-items: center;
    }
    .noleggio-data {
        flex-direction: column;
    }
    .noleggio-data-barralaterale-titolo {
        flex-basis: 100%;
    }
    .responsive-table td:last-child {
        width: 100%;
    }
    .container-select {
        align-self: center;
        width: 90vw;
    }
    .noleggio-main-container {
        flex-basis: 100%;
        display: flex;
        flex-direction: column;
    }
    .btn-filter-table{
        display: flex;
        flex-direction: column;
    }
    .btn-filter-table button{
        margin: 10px;
    }
    .filtri-noleggio {
        display: flex;
        flex-direction: column;
    }
    .noleggio-data-corpo-centrale {
       padding: 0;
       margin: 0;
    }
    .noleggio{
        width: 100VW;
        overflow: hidden;
    }
} 





@media (max-width: 768px) {
    .noleggio-data-barralaterale {
        display: none; /* Nasconde la barra laterale */
    }
    .a-icon{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .material-symbols-outlined {
        font-variation-settings:
        'FILL' 0,
        'wght' 400,
        'GRAD' 0,
        'opsz' 24
        }
    /* Navbar stile smartphone */
    .mobile-navbar {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: #007bff;
        color: #fff;
        padding: 10px 0;
       /*box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);*/
        z-index: 10000000000000000000;
        gap:20px;
    }

    .mobile-navbar a {
        color: #fff;
        text-decoration: none;
        font-size: 14px;
    }

    .mobile-navbar a:hover {
        text-decoration: underline;
    }
}