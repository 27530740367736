.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.nav-bar {
  display: flex;
  justify-content: space-between;
  background-color: #1f63ea;
  color: white;
  padding: 10px;

  align-items: baseline;
}

.ul-nav-bar {
  display: flex;
  list-style-type: none;
  flex-basis: 25%;
  justify-content: right;
  align-self: center;
}

.li-nav-bar {
  margin: 0 10px;
}
.li-nav-bar > a {
  color: rgb(228, 221, 221);
} 

.header-nav-bar {
  color: white;
  padding: 10px;
  /* tutta a sinistra */
  margin-left: 0;
  position: relative;
  flex-basis: 50%;
  display: flex;
  justify-content: center;
}

.logo-nav-bar {
  color: white;
  padding: 10px;
  /* tutta a sinistra */
  margin-left: 0;
  position: relative;
  align-self: center;
  flex-basis: 25%;
  height: 80px;
  width: 80px;
}

.logo-nav-bar > img {

  height: 100%;
}

/* Contenitore principale per un layout flessibile */
.header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  background-color: #1f63ea; /* Sfondo in linea con il resto del tema */
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Ombra per un effetto di profondità */
  max-width: 600px;
  margin: 20px auto;
}

/* Stile per l'intestazione principale */
/* Contenitore principale */
.header-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  background-color: #1f63ea;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  width: 100%;
}

/* Stile per l'intestazione principale */
.header-page {
  color: #000000;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 10px;
  padding: 0;
  text-align: left;
}

/* Stile per il paragrafo di benvenuto */
.benvenuto-paragraph {
  color: #000000;
  padding: 10px 15px;
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  margin: 6px;
}

/* Stile per ridurre lo spazio tra il paragrafo e il componente successivo */
.header-container + .login-container {
  margin-top: 10px;
}


/* creo per le classi sopra media query */

@media (max-width: 768px) {

  .nav-bar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #1f63ea;
    color: white;
    padding: 10px;
  }

  .ul-nav-bar {
    display: flex;
    list-style-type: none;
    flex-basis: 25%;
  }

  .li-nav-bar {
    margin: 0 10px;
  }
  .li-nav-bar > a {
    color: rgb(228, 221, 221);
  } 

  .header-nav-bar {
    color: white;
    padding: 10px;
    /* tutta a sinistra */
    margin-left: 0;
    position: relative;
    flex-basis: 50%;
    display: flex;
    justify-content: center;
  }

  .logo-nav-bar {
    color: white;
    padding: 10px;
    /* tutta a sinistra */
    margin-left: 0;
    position: relative;
    width: 40px !important;
    height: 40px !important;
    align-self: center;
    flex-basis: 25%;
  }
  .ul-nav-bar {
  padding: 0;
  }
}

