/* Container principale */
.gestisciPrezzo {
    /*max-width: 600px;*/
    margin: 20px auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
    flex-basis: 100%;
}

/* Sezione specifica per il prezzo della pulizia */
.prezzo-pulizia {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

/* Titolo sezione */
.prezzo-pulizia h3 {
    font-size: 24px;
    color: #333;
    text-align: center;
    margin-bottom: 10px;
    font-weight: 600;
}

/* Etichette */
.prezzo-pulizia label {
    font-size: 14px;
    font-weight: 600;
    color: #555;
    margin-bottom: 5px;
}

/* Select styling */
.prezzo-select {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid #ddd;
    background-color: #f7f7f7;
    transition: border-color 0.2s ease;
}

.prezzo-select:focus {
    outline: none;
    border-color: #1f63ea;
    box-shadow: 0 0 5px rgba(31, 99, 234, 0.2);
}

/* Input per il prezzo */
.prezzo-pulizia input[type='number'] {
    width: calc(100% - 20px);
    padding: 10px;
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid #ddd;
    background-color: #f7f7f7;
    transition: border-color 0.2s ease;
}

.prezzo-pulizia input[type='number']:focus {
    outline: none;
    border-color: #1f63ea;
    box-shadow: 0 0 5px rgba(31, 99, 234, 0.2);
}

/* Bottone di azione */
.btn-prezzo-gestore {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: #28a745;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.btn-prezzo-gestore:hover {
    background-color: #218838;
    transform: scale(1.02);
}

/* Effetto al passaggio del mouse per input e select */
.prezzo-pulizia input[type='number']:hover,
.prezzo-select:hover {
    border-color: #1f63ea;
}

/* Responsive design */
@media (max-width: 600px) {
    .gestisciPrezzo {
        padding: 15px;
    }

    .prezzo-pulizia {
        gap: 10px;
    }

    .btn-prezzo-gestore {
        padding: 10px;
        font-size: 14px;
    }
}
