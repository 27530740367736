/* Contenitore principale */
.magazzino-container {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
    color: #333;
}

/* Titolo */
.magazzino-title {
    font-size: 24px;
    font-weight: bold;
    color: #1f63ea;
    margin-bottom: 20px;
    text-align: center;
}

/* Form */
.magazzino-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

/* Contenitore input */
.magazzino-input-container {
    display: flex;
    flex-direction: column;
}

/* Etichette */
.magazzino-label {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #495057;
}

/* Input */
.magazzino-input {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    color: #495057;
    border: 1px solid #ced4da;
    border-radius: 5px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.magazzino-input:focus {
    border-color: #1f63ea;
    box-shadow: 0 0 5px rgba(31, 99, 234, 0.5);
    outline: none;
}

/* Pulsante */
.magazzino-button {
    padding: 10px 15px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: #1f63ea;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.magazzino-button:hover {
    background-color: #155bb5;
}

.magazzino-button:active {
    background-color: #103f88;
}

.magazzino-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
    font-size: 14px;
}

.magazzino-table th,
.magazzino-table td {
    padding: 12px;
    border: 1px solid #dee2e6;
}

.magazzino-table th {
    background-color: #1f63ea;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
}

.magazzino-table tr:nth-child(even) {
    background-color: #f8f9fa;
}

.magazzino-table tr:hover {
    background-color: #f1f3f5;
}

.magazzino-table .delete {
    background-color: #ff4d4d;
    color: white;
}

.magazzino-table .delete:hover {
    background-color: #d43d3d;
}


/* Responsività */
@media (max-width: 768px) {
    .magazzino-container {
        padding: 15px;
    }

    .magazzino-title {
        font-size: 20px;
    }

    .magazzino-input {
        font-size: 13px;
    }

    .magazzino-button {
        font-size: 14px;
        padding: 8px 12px;
    }
    .magazzino-table {
        font-size: 12px;
    }

    .magazzino-table th,
    .magazzino-table td {
        padding: 8px;
    }
    
}
@media (max-width: 480px) {
    .magazzino-table {
        display: block;
        overflow-x: auto;
        white-space: nowrap;
    }

    .magazzino-table thead {
        display: none;
    }

    .magazzino-table tr {
        display: block;
        margin-bottom: 15px;
    }

    .magazzino-table td {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px;
        border: 1px solid #dee2e6;
    }

    .magazzino-table td:before {
        content: attr(data-label);
        font-weight: bold;
        text-transform: uppercase;
        color: #495057;
    }
}