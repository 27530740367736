/* Contenitore principale */
.main-pulizie {
    width: 100%;
    max-width: 800px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.main-pulizie h1 {
    font-size: 24px;
    color: #1f63ea;
    margin-bottom: 20px;
    font-weight: bold;
}

/* Stile per la tabella */
.table-client {
    width: 100%;
    border-collapse: collapse;
    margin-top: 15px;
    font-size: 16px;
    color: #333;
}

/* Stile per l'intestazione della tabella */
.thead-client th {
    background-color: #1f63ea;
    color: #ffffff;
    padding: 12px 8px;
    font-weight: bold;
    text-transform: uppercase;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

/* Stile per il corpo della tabella */
.tbody-client td {
    padding: 10px 8px;
    border-bottom: 1px solid #ddd;
}

/* Riga alternata */
.tbody-client tr:nth-child(even) {
    background-color: #f9f9f9;
}

/* Riga hover */
.tbody-client tr:hover {
    background-color: #eaeaea;
    cursor: pointer;
}

/* Valori delle celle */
.tbody-client td {
    text-align: center;
}

/* Responsività */
@media (max-width: 768px) {
    .main-pulizie {
        padding: 15px;
    }

    .main-pulizie h1 {
        font-size: 20px;
    }

    .table-client {
        font-size: 14px;
    }

    .thead-client th,
    .tbody-client td {
        padding: 8px;
    }

    /* Tabella a schede per dispositivi mobili */
    .table-client {
        display: block;
    }

    .thead-client {
        display: none; /* Nasconde l'intestazione della tabella */
    }

    .tbody-client tr {
        display: block;
        margin-bottom: 10px;
        border: 1px solid #ddd;
        border-radius: 8px;
        padding: 10px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
        background-color: #fff;
    }

    .tbody-client td {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 5px;
        text-align: left;
        font-size: 14px;
    }

    .tbody-client td::before {
        content: attr(data-label); /* Usa gli attributi data-label per i titoli delle colonne */
        font-weight: bold;
        margin-right: 10px;
        color: #1f63ea;
        flex-shrink: 0;
    }
}
