/* Layout container principale */
.card-checkout {
    margin: 0 auto;
   /*s padding: 20px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);*/
    font-family: 'Arial', sans-serif;
    height: 75vh;
}

/* Stile per l'immagine */
.img-prenota {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 15px;
}

/* Titolo della prenotazione */
.h3-prenota {
    font-size: 22px;
    color: #333;
    text-align: center;
    margin-bottom: 10px;
    font-weight: bold;
}

/* Dettagli ordine */
.card-checkout p {
    font-size: 14px;
    color: #555;
    margin: 5px 0;
}

/* Checkbox per fatturazione */
.card-checkout label {
    display: block;
    font-size: 16px;
    font-weight: bold;
    margin: 10px 0;
    color: #333;
}

/* Input checkbox */
.card-checkout input[type="checkbox"] {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    cursor: pointer;
}

/* Form di fatturazione */
.form-fatturazione {
    margin-top: 20px;
    padding: 15px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
}

.form-fatturazione h3 {
    font-size: 18px;
    color: #333;
    margin-bottom: 15px;
}

/* Stile per input del form */
.form-fatturazione input[type="text"] {
    width: calc(100% - 10px);
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 14px;
    box-sizing: border-box;
}

.form-fatturazione input[type="text"]:focus {
    outline: none;
    border-color: #1f63ea;
    box-shadow: 0 0 5px rgba(31, 99, 234, 0.2);
}

/* Bottone conferma fatturazione */
.form-fatturazione button {
    width: 100%;
    padding: 10px;
    background-color: #1f63ea;
    color: #fff;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.form-fatturazione button:hover {
    background-color: #144bb1;
}

/* Bottone checkout */
.card-checkout button {
    width: 100%;
    padding: 12px;
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 15px;
}

.card-checkout button:hover {
    background-color: #218838;
}

/* Stile per il bottone di checkout di Stripe */
.checkout-button {
    width: 100%;
    margin-top: 10px;
}

/* Effetto al passaggio del mouse */
.card-checkout input[type="checkbox"]:hover,
.card-checkout button:hover {
    transform: scale(1.02);
}

/* Transizione per i checkbox e bottoni */
.card-checkout input[type="checkbox"],
.card-checkout button {
    transition: transform 0.2s ease;
}


.btn-prenota {
    background-color: #1f63ea;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 12px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.container-image-card {
    display: flex;
    justify-content: center;
    align-items: center;
}

.cont-img-riepilogo {
    display: flex;
    justify-content: center;
}