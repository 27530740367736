.main-data-picker {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    padding: 15px;
  }
  
  .data-picker-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 15px;
    border-radius: 12px;
    background-color: #ffffff;
    color: #333;
    height: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    gap: 20px;
  }
  
  .cont-inp-datepicker {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  
  .cont-inp-datepicker label {
    font-size: 14px;
    font-weight: bold;
    color: #333;
  }
  
  .react-datepicker__input-container > input {
    min-width: 100px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .react-datepicker__input-container > input:focus {
    outline: none;
    border-color: #1f63ea;
  }
  
  /* Stile mobile */
  @media (max-width: 768px) {
    .data-picker-container {
      flex-direction: column;
      align-items: center;
      padding: 10px;
      gap: 15px;
    }
  
    .cont-inp-datepicker {
      flex-direction: column;
      gap: 5px;
    }
  
    .react-datepicker__input-container > input {
      width: 100%;
      min-width: unset;
      font-size: 12px;
    }
  
    .cont-inp-datepicker label {
      font-size: 12px;
    }
  }
  