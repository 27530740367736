/* Contenitore principale */
.giro {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    color: #333;
    flex-basis: 100%;
}

/* Titolo principale */
.giro h2 {
    font-size: 24px;
    font-weight: bold;
    color: #1f63ea;
    margin-bottom: 20px;
    text-align: center;
}

/* Paragrafo di caricamento o errore */
.giro p {
    font-size: 16px;
    color: #555;
    text-align: center;
}

/* Messaggio di errore */
.giro p.error {
    color: #d9534f;
    font-weight: bold;
}

/* Contenitore per i bottoni */
.cont-button-bagni-giro {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
}

/* Stile dei bottoni */
.cont-button-bagni-giro button {
    padding: 10px 20px;
    background-color: #1f63ea;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* Hover sui bottoni */
.cont-button-bagni-giro button:hover {
    background-color: #0056b3;
}

/* Stato attivo dei bottoni */
.cont-button-bagni-giro button:active {
    background-color: #003f7f;
}

/* Contenitore per la mappa o la tabella */
.mostra-mappa-giro,
.mostra-tabella-giro {
    margin-top: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.pointer-img {
    cursor: pointer;
}

/* Responsività per schermi più piccoli */
@media (max-width: 768px) {
    .cont-button-bagni-giro {
        flex-direction: column;
        gap: 10px;
    }

    .giro h2 {
        font-size: 20px;
    }

    .cont-button-bagni-giro button {
        width: 100%;
        padding: 12px;
    }
    .giro {
        padding:0px;
        width: 90vw;
    }
}
