.switch-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
    justify-content: center;
    margin-bottom: 15px;
}

.switch-label {
    font-weight: bold;
    padding: 0 10px;
    color: #555;
}

.switch-label.active {
    color: #007bff;
}

.switch {
    width: 50px;
    height: 25px;
    background-color: #ccc;
    border-radius: 25px;
    position: relative;
    cursor: pointer;
    margin: 0 10px;
}

.switch-button {
    width: 23px;
    height: 23px;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    top: 1px;
    transition: all 0.3s ease;
}

.switch-button.left {
    left: 1px;
}

.switch-button.right {
    left: 26px;
}
