/* Contenitore principale del form di ricerca */
.form-container-search-bagni {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    margin-top: 120px;
}

/* Stile per l'etichetta del campo di ricerca */
.form-container-search-bagni label {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
}

/* Stile per l'input di ricerca */
.form-container-search-bagni input[type="text"] {
    width: 100%;
    max-width: 600px;
    padding: 12px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
    box-sizing: border-box;
    box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease;
}

.form-container-search-bagni input[type="number"] {
    width: 100%;
    max-width: 600px;
    padding: 12px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
    box-sizing: border-box;
    box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease;
}

/* Focus input */
.form-container-search-bagni input[type="text"]:focus {
    border-color: #1f63ea;
    outline: none;
    box-shadow: 0 0 5px rgba(31, 99, 234, 0.3);
}

.form-container-search-bagni input[type="number"]:focus {
    border-color: #1f63ea;
    outline: none;
    box-shadow: 0 0 5px rgba(31, 99, 234, 0.3);
}

/* Stile responsive per l'iframe di Google Maps */
.form-container-search-bagni iframe {
    width: 50%;
    max-width: 50%;
    height: 200px; /* Altezza predefinita su desktop */
    border: none;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
}

.label-search-bagni {
    display: flex;
    width: 100%;
    max-width: 600px;
    justify-content: left;
}
.h2-search-bagni {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
}

.select-search-bagni {
    width: 100%;
    max-width: 600px; /* Larghezza massima */
    padding: 12px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    color: #333;
    background-color: white;
    box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.1);
    appearance: none; /* Nasconde l'icona predefinita del browser */
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%204%205'%3E%3Cpath%20fill='none'%20stroke='%23333'%20stroke-width='1.5'%20d='M0%200L2%202L4%200'/%3E%3C/svg%3E"); /* Freccia personalizzata */
    background-repeat: no-repeat;
    background-position: right 10px top 50%;
    background-size: 12px;
    cursor: pointer;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}
.prenotazione-spiegazione {
    text-align: center;
    background-color: #f1f1f1;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.prenotazione-spiegazione h2 {
    color: #1f63ea;
    font-size: 28px;
    margin-bottom: 10px;
}

.prenotazione-spiegazione p {
    font-size: 18px;
    color: #333;
    margin-bottom: 10px;
}

/* Focus input select */
.select-search-bagni:focus {
    border-color: #1f63ea;
    box-shadow: 0 0 5px rgba(31, 99, 234, 0.3);
    outline: none;
}

/* Stile per gli option */
.select-search-bagni option {
    padding: 10px;
    font-size: 16px;
    background-color: white;
    color: #333;
}

.button-search-bagni {
    padding: 12px 20px;
    background-color: #1f63ea;
    color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
.button-search-bagni button {
    padding: 12px 20px;
    background-color: #1f63ea;
    color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* Effetto hover per i pulsanti */
.container-aggiungi-servizio button:hover {
    background-color: #0056b3;
}
.button-search-bagni button:hover {
    background-color: #0056b3;
}

/* General Styles */
body {
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    color: #333;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #f7f7f7;
}

h1, h2 {
    color: #1f63ea;
    margin-bottom: 20px;
}

h1 {
    font-size: 36px;
}

h2 {
    font-size: 28px;
}

/* Hero Section */
.hero-section {
    background-color: #1f63ea;
    color: white;
    padding: 80px 20px;
    text-align: center;
    background-image: url('../../../image/header_1.jpeg');
    background-size: cover;
    background-position: center;
    height: 500px;
}

.hero-title {
    font-size: 48px;
    margin-bottom: 10px;
    color: #353232;
    font-weight: 600;
}

.hero-subtitle {
    font-size: 24px;
    margin-bottom: 30px;
    color: black;
    font-weight: 500;
}

.cta-button {
    background-color: #ff5722;
    color: white;
    border: none;
    padding: 15px 30px;
    font-size: 18px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.cta-button:hover {
    background-color: #e64a19;
}

/* About Section */
.about-section {
    padding: 40px 20px;
    text-align: center;
    background-color: #fff;
}

.about-section p {
    font-size: 18px;
    max-width: 800px;
    margin: 0 auto;
}

/* Services Section */
.services-section {
    padding: 40px 20px;
    background-color: #f1f1f1;
}

.services-section ul {
    list-style-type: none;
    padding: 0;
    max-width: 800px;
    margin: 0 auto;
}

.services-section li {
    font-size: 18px;
    margin-bottom: 15px;
    padding-left: 20px;
    position: relative;
}

.services-section li:before {
    content: "✔";
    color: #1f63ea;
    font-weight: bold;
    position: absolute;
    left: 0;
    top: 0;
}

/* Why Choose Section */
.why-choose-section {
    padding: 40px 20px;
    background-color: #fff;
}

.why-choose-section ul {
    list-style-type: none;
    padding: 0;
    max-width: 800px;
    margin: 0 auto;
}

.why-choose-section li {
    font-size: 18px;
    margin-bottom: 15px;
    padding-left: 20px;
    position: relative;
}

.why-choose-section li:before {
    content: "⭐";
    color: #ff5722;
    font-weight: bold;
    position: absolute;
    left: 0;
    top: 0;
}

/* Call to Action Section */
.call-to-action {
    background-color: #1f63ea;
    color: white;
    padding: 40px 20px;
    text-align: center;
}

.call-to-action p {
    font-size: 20px;
    margin-bottom: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .hero-title {
        font-size: 36px;
    }

    .hero-subtitle {
        font-size: 20px;
    }

    .cta-button {
        font-size: 16px;
        padding: 12px 25px;
    }

    .services-section li, .why-choose-section li {
        font-size: 16px;
    }
}

@media (max-width: 480px) {
    .hero-title {
        font-size: 28px;
    }

    .hero-subtitle {
        font-size: 18px;
    }

    .cta-button {
        font-size: 14px;
        padding: 10px 20px;
    }

    .services-section li, .why-choose-section li {
        font-size: 14px;
    }
}

/* Stile responsivo per schermi piccoli */
@media (max-width: 768px) {
    .form-container-search-bagni {
        padding: 15px;
    }

    /* Input più piccolo per schermi piccoli */
    .form-container-search-bagni input[type="text"] {
        font-size: 14px;
        padding: 10px;
    }

    .form-container-search-bagni input[type="number"] {
        font-size: 14px;
        padding: 10px;
    }

    /* Altezza ridotta dell'iframe su dispositivi mobili */
    .form-container-search-bagni iframe {
        height: 200px;
    }
}

/* Stile responsivo per schermi molto piccoli (smartphone) */
@media (max-width: 480px) {
    .form-container-search-bagni {
        padding: 10px;
    }

    .form-container-search-bagni label {
        font-size: 16px;
    }

    .form-container-search-bagni input[type="text"] {
        font-size: 14px;
        padding: 8px;
    }

    .form-container-search-bagni input[type="number"] {
        font-size: 14px;
        padding: 8px;
    }

    /* Altezza minima dell'iframe su smartphone */
    .form-container-search-bagni iframe {
        height: 150px;
    }
}


/* Contenitore principale dello switch */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin-right: 10px;
}

/* Nascondi il checkbox reale (lasciamo solo lo switch) */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* Lo slider dello switch */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
}

/* Colore dello slider quando è attivato */
.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

/* Quando l'input è attivo (checked), cambia colore dello slider */
input:checked + .slider {
    background-color: #1f63ea;
}

/* Sposta il pulsante a destra quando l'input è attivo */
input:checked + .slider:before {
    transform: translateX(26px);
}

/* Stile dello slider arrotondato */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

/* Stile per l'etichetta accanto allo switch */
.switch-label {
    font-size: 16px;
    font-weight: bold;
    margin-left: 10px;
    color: #333;
    vertical-align: middle;
}


/* Stile per il contenitore del form */
.form-check-radio {
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
}

/* Stile per i gruppi radio */
.form-check-radio input[type="radio"] {
    margin-right: 10px;
}

/* Stile per le etichette radio */
.form-check-radio label {
    font-size: 16px;
    color: #333;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 500;
    padding: 8px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

/* Hover effetto su radio e label */
.form-check-radio input[type="radio"]:hover + label,
.form-check-radio label:hover {
    background-color: #eaeaea;
}

/* Stile per il radio button selezionato */
.form-check-radio input[type="radio"]:checked + label {
    background-color: #1f63ea;
    color: white;
}

/* Responsività: layout più compatto per dispositivi mobili */
@media (max-width: 768px) {
    .form-check-radio {
        padding: 15px;
        gap: 10px;
    }

    .form-check-radio label {
        font-size: 14px;
    }
}


#no, #yes1, #yes2, #yes3, #yes4, #yes5, #yes6 input[type="radio"] {
    display: none;
}

.radio-pulizie {
    display:none;
}